import { ChildTrackingFeature, type TrackEventStatus, TrackingFeature, type TrackingFeatureLabels } from "../FeatureTrackingData";
import type { TrackingBenefit } from "./Tracking";

const TRACKING_PARENT_ID = "FT9-benefit-main";

export function buildTrackingPayloadCinema(status: TrackEventStatus, cinemaDom: HTMLElement): TrackingFeature {
	const cinemaTrackingLabels = JSON.parse(cinemaDom.dataset.cinemaTracking ?? "{}") as TrackingFeatureLabels;
	const cinemaCountLabelValue = cinemaDom.parentElement?.parentElement?.dataset.featureIndex ?? 1; /*                                                                                         */

	const [position, slot] = getFeaturePosition(cinemaDom);

	const labels: TrackingFeatureLabels = {
		...cinemaTrackingLabels,
		benefit_CinemaCount: [`${cinemaCountLabelValue}`],
	};

	if (slot) {
		labels.benefit_Slot = [slot];
	}

	return new TrackingFeature(`${TRACKING_PARENT_ID}-${slot}-${position}`, "BenefitCinema", status, labels, position);
}

/*                                              */
export function getFeaturePosition(cinemaDom: HTMLElement): [number, string | undefined] {
	/*                                                         */
	const topSlotPosition = cinemaDom.parentElement?.parentElement?.dataset.topSlot;
	if (topSlotPosition) return [Number.parseInt(topSlotPosition), "top"];

	/*                                                             */
	const tilelistSlotPosition = cinemaDom.parentElement?.parentElement?.dataset.tilelistSlot;
	if (tilelistSlotPosition) return [Number.parseInt(tilelistSlotPosition), "tilelist"];

	return [0, undefined];
}

export function buildTrackingPayloadSlide(status: TrackEventStatus, benefitDom: HTMLElement, parentId: string): ChildTrackingFeature {
	const tracking = JSON.parse(benefitDom.dataset.tracking ?? "{}") as TrackingBenefit;

	const benefitPosition = tracking.position;
	return new ChildTrackingFeature(`${parentId}-${tracking.id}${benefitPosition}`, tracking.name, status, benefitPosition, tracking.labels, parentId);
}
